import React, { useState, useEffect, useRef, useMemo } from "react";
import clsx from "clsx";
// import * as moment from "moment";
// Material
// import Typography from "@material-ui/core/Typography";
// import TextField from "@material-ui/core/TextField";
// Custom Hooks
import useDynamicHotelData from "../../../CustomHooks/useDynamicHotelData";
import useWorkingInfo from "../../../CustomHooks/useWorkingInfo";
// Styles
import useStyles from "./DeliveryTime.style.js";
// Constans
import {
  // DELIVERY_PICKUP_TYPES,
  ORDER_TYPES,
} from "../../../utils/Constants";
import DateSlotPicker from "../DateSlotPicker";
import TimePicker from "../TimePicker";
import { useDispatch, useSelector } from "react-redux";
import * as moment from "moment";
import { OutlinedInput } from "@material-ui/core";
import CheckBox from "../../Controls/Checkbox/CheckBox";
// import { showSnackbarAction } from "../../../store/Snackbar/SnackbarAction";
// import { convertToMoment } from "../../../utils/TimeUtils";
import { generateDateSlots } from "../../../utils/utils";
import Btn from "../../Controls/Button/Button";
import Modal from "../../Material/Modal/Modal";
import TableListing from "../../TableListing/TableListing";
import {
  clearTableSelection,
  clearTipAmount,
} from "../../../store/Cart/CartActions";
import { ENABLE_TABLE_PRE_BOOKING_AND_ORDER } from "../../../config/app-config";
import useCheckoutForm from "../../../CustomHooks/useCheckoutForm.js";
import { resetWallet } from "../../../store/Profile/ProfileActions.js";
import useWalletPoints from "../../../CustomHooks/useWalletPoints.js";

const DeliveryTime = ({
  value,
  orderType,
  onSelect = () => {},
  onOrderTypeSelect = () => {},
}) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  // const [deliveryType, setDeliveryType] = useState(value.deliveryType || "");
  const [date, setDate] = useState(null);
  const [time, setTime] = useState(null);
  const [dateSlots, setDateSlots] = useState([]);
  const { formValues, setFormValues } = useCheckoutForm();

  // const { getDeliveryPickupTypes } = useDynamicHotelData();
  const { defaultStartTime } = useWorkingInfo();
  const {
    getDeliveryIntervalSlots,
    getTodayDeliveryIntervalSlots,
    isAsapAvailable,
    // getAvailableDays,
    isMultipleOrderType,
    getOrderTypes,
  } = useDynamicHotelData();
  const { hotel } = useSelector((state) => state.hotel);
  const { selectedTable, checkoutDetails } = useSelector((state) => state.cart);
  const [typeOfOrder, setTypeOfOrder] = useState(checkoutDetails?.deliveryType);
  const [dateSlotsError, setDateSlotsError] = useState(false);
  const [showModal, toggleModal] = useState(false);
  const [isCheckBoxDisabled, setIsCheckBoxDisabled] = useState(false);
  const { resetWalletProducts } = useWalletPoints();
  const [newChange, setNewChange] = useState(false);
  const [typeChanges, setTypeChanges] = useState(false);

  const generateDatePickerSlots = () => {
    let orderTypeValue = orderType;
    if (orderType === ORDER_TYPES.TABLE_BOOKING_AND_ORDERING) {
      orderTypeValue = ORDER_TYPES.OPENING;
    }
    const dates = generateDateSlots(hotel, orderTypeValue) || [];
    setDateSlots(dates);
    if (!dates || !dates?.length) {
      setDateSlotsError(true);
    }
  };
  // console.log("isMultipleOrderType", isMultipleOrderType);
  // console.log("new changeeeeee", newChange);
  const onSelectDataAndTime = ({ value, typeOfOrder } = {}, type) => {
    if (!value) return;

    // Set typeOfOrder if provided
    if (typeOfOrder) {
      setTypeOfOrder(typeOfOrder);
    }

    // When a date is selected
    if (type === "date") {
      setDate((prevDate) => {
        if (prevDate) {
          if (prevDate !== value) {
            setNewChange(true); // Trigger newChange only if the date is different
          }
        }

        // Automatically select the first available time slot for the new date
        const availableTimeSlots = getAvailableTimeSlotsForDate(value);
        if (availableTimeSlots && availableTimeSlots.length > 0 && newChange) {
          const firstTimeSlot = availableTimeSlots[0].value; // Assuming time slot object has a 'value' property
          setTime((prevTime) => {
            if (prevTime !== firstTimeSlot) {
              setNewChange(true); // Trigger newChange if time changes
            }
            return firstTimeSlot; // Set the first available time
          });
        }

        return value; // Set the new date
      });
    }

    // When a time is selected manually
    if (type === "time") {
      setTime((prevTime) => {
        if (prevTime !== value) {
          setNewChange(true); // Trigger newChange only if the time is different
        }
        return value;
      });
    }
  };

  const getAvailableTimeSlotsForDate = (selectedDate) => {
    // Get the time slots for today or another date based on the selected date
    const availableSlots = moment(selectedDate).isSame(moment(), "date")
      ? getTodayDeliveryIntervalSlots
      : getDeliveryIntervalSlots;

    // Add condition to filter for "SCHEDULED" time slots if orderTimeType is "SCHEDULED"
    if (typeOfOrder === "SCHEDULED") {
      return availableSlots.filter((slot) => slot.typeOfOrder === "SCHEDULED");
    }

    return availableSlots; // Return all available slots if not filtered
  };

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false; // Skip the first render
    } else if (newChange || !isMultipleOrderType) {
      // console.log("dateeeeeeee", date);

      const timeString = `${date}${time ? ` ${time}` : ""}`;
      const dTime = moment(timeString).toISOString();

      onSelect(typeOfOrder || "SCHEDULED", dTime);

      setNewChange(false); // Reset newChange after processing
    }
  }, [date, time, typeOfOrder, newChange, isMultipleOrderType]);
  // console.log("hotel?.deliveryAvailable", hotel?.deliveryAvailable);
  // console.log("hotel?.pickupAvailable", hotel?.pickupAvailable);

  useEffect(() => {
    if (hotel) {
      if (hotel?.is_preorder === 0) {
        if (
          hotel?.deliveryAvailable === false ||
          hotel?.pickupAvailable === false
        ) {
          setIsCheckBoxDisabled(true);
        } else {
          setIsCheckBoxDisabled(false);
        }
      } else {
        setIsCheckBoxDisabled(false);
      }
    }
  }, [hotel]);

  const isFirstRender = useRef(true); // Add this ref to track the first render

  useEffect(() => {
    // If isMultipleOrderType is false or orderType changes, set the first available time as the default
    if (!isMultipleOrderType && isFirstRender.current) {
      const slots =
        moment(value.deliveryTime).isSame(moment(), "date") &&
        getTodayDeliveryIntervalSlots.length > 0
          ? getTodayDeliveryIntervalSlots
          : getDeliveryIntervalSlots;
      if (slots.length > 0) {
        // Set the first date and time slot if they are available
        const firstSlot = slots[0]; // Assuming the slots have a consistent structure
        const firstTime = firstSlot?.value; // Adjust based on your slot structure
        const currentDate = moment().format("YYYY-MM-DD"); // Assuming you want to use today's date as default
        setDate(currentDate);
        setTime(firstTime);
      }

      isFirstRender.current = false;
    }
  }, [
    isMultipleOrderType,
    getTodayDeliveryIntervalSlots,
    getDeliveryIntervalSlots,
    value.deliveryTime,
    orderType,
  ]);

  useEffect(() => {
    if (hotel || formValues?.orderType) {
      generateDatePickerSlots();
    }
  }, [hotel, formValues?.orderType]);

  const isScheduledAvailable = useMemo(() => {
    return (
      (Array.isArray(getTodayDeliveryIntervalSlots) &&
        getTodayDeliveryIntervalSlots.length > 0) ||
      (Array.isArray(getDeliveryIntervalSlots) &&
        getDeliveryIntervalSlots.length > 0)
    );
  }, [getTodayDeliveryIntervalSlots, getDeliveryIntervalSlots]);

  useEffect(() => {
    if (formValues?.orderType && getOrderTypes?.length > 0) {
      const timeoutId = setTimeout(() => {
        // Find the current order type in the getOrderTypes array
        const currentOrderType = formValues?.orderType;

        // Find the type to switch to, based on the current orderType
        const nextOrderType = getOrderTypes.find(
          (typeObj) => typeObj.type !== currentOrderType
        );

        // If there's a valid type to switch to, update the orderType
        if (!isAsapAvailable && !isScheduledAvailable && nextOrderType) {
          onOrderTypeSelect(nextOrderType.type);
        }
      }, 1000); // 2-second timeout

      // Cleanup function to clear the timeout if dependencies change
      return () => clearTimeout(timeoutId);
    }
  }, [
    formValues?.orderType,
    getOrderTypes,
    isAsapAvailable,
    isScheduledAvailable,
  ]);

  const onClick = () => {
    setNewChange(true);
  };

  // const selectDeliveryTime = (type) => {
  //   setDeliveryType(type);
  //   if (type === DELIVERY_PICKUP_TYPES.ASAP) {
  //     const dTime = defaultStartTime;
  //     onSelect(type, dTime);
  //   }
  // };
  // console.log("hotelllll", hotel)

  useEffect(() => {
    if (dateSlots?.length === 0) {
      setDate(null);
    }
  }, [dateSlots]);

  useEffect(() => {
    if (!orderType) {
      return;
    }

    if (orderType !== ORDER_TYPES.TABLE_BOOKING_AND_ORDERING) {
      dispatch(clearTableSelection());
    }
  }, [orderType]);

  // if (dateSlots?.length === 0) {
  //   return (
  //     <div className={clsx(classes.root)}>
  //       <div className={clsx(classes.container)}>
  //         <p>
  //           {!dateSlotsError
  //             ? "Loading time slots...."
  //             : "Delivery time finished"}
  //         </p>
  //       </div>
  //     </div>
  //   );
  // }

  // console.log("getOrderTypes", getOrderTypes);

  // const isAsapUnAvailable = (o) => {
  //   return o.type === "ASAP" && !isAsapAvailable;
  // };

  // const isPreOrderAvailable = (o) => {
  //   return o.type === "SCHEDULED" && hotel.is_preorder === 0;
  // };

  return (
    <div id="checkout-deliveryPickupTypeForm" className={clsx(classes.root)}>
      <div>
        <table className={`w-100 ${classes.borderTable}`}>
          <tbody>
            <tr>
              <td colSpan="2">
                <div className="row m-0">
                  {getOrderTypes?.map((o, i) => (
                    <div key={i} className={`col ${classes.checkBoxTd}`}>
                      <CheckBox
                        isFromCheckout={true}
                        text={o.title}
                        checked={formValues?.orderType === o.type}
                        disabled={
                          (o.type === "DELIVERY" &&
                            hotel?.is_preorder === 0 &&
                            !hotel?.deliveryAvailable) ||
                          (o.type === "PICKUP" &&
                            hotel?.is_preorder === 0 &&
                            !hotel?.pickupAvailable)
                        }
                        onChange={(e) => {
                          e.stopPropagation();
                          onOrderTypeSelect(o.type);
                          dispatch(resetWallet());
                          resetWalletProducts(2);
                          setTypeChanges(true);
                          dispatch(clearTipAmount());
                        }}
                        classes={{
                          checkBoxContainer: classes.checkBoxContainer,
                        }}
                      />
                    </div>
                  ))}
                </div>
              </td>
            </tr>
            {ENABLE_TABLE_PRE_BOOKING_AND_ORDER && (
              <tr>
                <td
                  colSpan={
                    orderType === ORDER_TYPES.TABLE_BOOKING_AND_ORDERING ? 1 : 2
                  }
                >
                  <div
                    className={`${classes.checkBoxTd} ${
                      orderType === ORDER_TYPES.TABLE_BOOKING_AND_ORDERING
                        ? "border-0"
                        : ""
                    }`}
                  >
                    <CheckBox
                      text={"Table booking"}
                      checked={
                        orderType === ORDER_TYPES.TABLE_BOOKING_AND_ORDERING
                      }
                      isFromCheckout={true}
                      onChange={(e) => {
                        e.stopPropagation();
                        onOrderTypeSelect(
                          ORDER_TYPES.TABLE_BOOKING_AND_ORDERING
                        );
                      }}
                      classes={{
                        checkBoxContainer: classes.checkBoxContainer,
                      }}
                    />
                  </div>
                </td>
                {orderType === ORDER_TYPES.TABLE_BOOKING_AND_ORDERING && (
                  <td>
                    <div
                      className={`${classes.selectTableCont} justify-conent-center align-items-center`}
                    >
                      {!selectedTable?.id ? (
                        <Btn
                          className={`${classes.btn}`}
                          text="Choose Table"
                          onClick={() => {
                            toggleModal(true);
                          }}
                        />
                      ) : (
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "10px",
                          }}
                        >
                          <OutlinedInput
                            fullWidth
                            classes={{
                              input: classes.input,
                            }}
                            value={selectedTable.name}
                            disabled={true}
                          />
                          <Btn
                            handleClick={() => {
                              dispatch(clearTableSelection());
                            }}
                            text="Clear"
                            className={classes.loginBtn}
                          />
                        </div>
                      )}
                    </div>
                  </td>
                )}
              </tr>
            )}
            <tr>
              <td className="w-50">
                <DateSlotPicker
                  slots={dateSlots}
                  className={`w-100 ${classes.dateTimeSelection}`}
                  onChange={onSelectDataAndTime}
                  isFromCheckout={true}
                  newChange={newChange}
                  setNewChange={setNewChange}
                />
              </td>
              <td className="w-50">
                <TimePicker
                  slots={
                    moment(value.deliveryTime).isSame(moment(), "date") &&
                    getTodayDeliveryIntervalSlots.length > 0
                      ? getTodayDeliveryIntervalSlots
                      : getDeliveryIntervalSlots
                  }
                  isFromCheckout={true}
                  date={date}
                  setTime={setTime}
                  onClick={onClick}
                  setNewChange={setNewChange}
                  className={`w-100 ${classes.dateTimeSelection}`}
                  onChange={onSelectDataAndTime}
                  typeChanges={typeChanges}
                  newChange={newChange}
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <Modal
        customView={true}
        open={showModal}
        handleClose={() => toggleModal(false)}
      >
        <TableListing
          handleClose={() => {
            toggleModal(false);
          }}
        />
      </Modal>
    </div>
  );
};

export default DeliveryTime;
