import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { FormControl, MenuItem, Select } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  // Define your styles here
  ul: {
    background: theme.palette.card.cardColor,
    color: theme?.palette?.card?.cardFontColor,
    "& ul": {
      color: theme.palette.card.cardFontColor,
      background: theme.palette.card.cardColor,
    },
  },
  selectMenu: {
    background: theme.palette.card.cardColor,
    "& li": {
      color: theme.palette.card.cardFontColor,
    },
  },
}));

function Index({
  slots,
  className,
  value,
  onChange,
  isFromCheckout = false,
  setNewChange,
}) {
  const [selectedDate, setSelectedDate] = useState({});
  const classes = useStyles();
  const { checkoutDetails } = useSelector((state) => state.cart);
  const [newDateChange, setNewDateChange] = useState(false);

  // console.log("slotssssssss", slots);
  // console.log("selected dateeeeeeee", selectedDate);

  const checkoutDate = checkoutDetails?.deliveryTime
    ? new Date(checkoutDetails.deliveryTime).toLocaleDateString("en-US", {
        month: "long",
        day: "numeric",
        year: "numeric",
      })
    : null;
  // useEffect(() => {

  //   if (!slots.includes(selectedDate)) {
  //     const data = slots[0];
  //     if (data) {
  //       setSelectedDate(data);
  //       onChange({ value: data.date }, "date");
  //     }

  //     return;
  //   }
  //   if (selectedDate) {
  //     onChange({ value: selectedDate.date }, "date");
  //   } else {
  //     const data = slots[0];
  //     if (data) {
  //       setSelectedDate(data);
  //       onChange({ value: data.date }, "date");
  //     }
  //   }
  // }, [selectedDate, slots]);

  useEffect(() => {
    // If component is from checkout and checkoutDetails.deliveryTime exists
    if (isFromCheckout && checkoutDate && !newDateChange) {
      // Find the slot where date matches checkoutDate
      const matchedSlot = slots.find((slot) => slot.date === checkoutDate);
      if (matchedSlot) {
        setSelectedDate(matchedSlot);
        onChange({ value: matchedSlot.date }, "date");
        return;
      }
    }

    // Default behavior if selectedDate is not in slots or no match is found
    if (!slots.includes(selectedDate)) {
      const data = slots[0];
      if (data) {
        setSelectedDate(data);
        onChange({ value: data.date }, "date");
      }
      return;
    }

    if (selectedDate) {
      onChange({ value: selectedDate.date }, "date");
    } else {
      const data = slots[0];
      if (data) {
        setSelectedDate(data);
        onChange({ value: data.date }, "date");
      }
    }
  }, [selectedDate, slots, checkoutDate, isFromCheckout, newDateChange]);

  const handleChange = () => {
    setNewDateChange(true);
  };

  return (
    <FormControl variant="outlined" className={className}>
      {/* <InputLabel id="delivery-time">Time</InputLabel> */}
      <Select
        labelId="delivery-time"
        id="delivery-date-select"
        value={selectedDate}
        onChange={(e) => setSelectedDate(e.target.value)}
        onClick={handleChange}
        label="Date"
        MenuProps={{
          classes: { paper: classes.selectMenu },
        }}
        className={`slotSelectorFont font-weight-bold ${classes.ul}`}
      >
        <MenuItem value="">
          <em>None</em>
        </MenuItem>
        {slots.map((elem, key) => (
          <MenuItem key={`option-${key}`} value={elem}>
            {elem.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

Index.defaultProps = {
  buffer: null,
};

Index.propTypes = {
  buffer: PropTypes.number,
  className: PropTypes.string.isRequired,
};

export default Index;
